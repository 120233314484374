import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

const GlobalStyle = createGlobalStyle`
  :root {
    --white: #ffffff;
    --black: #1d1d1b;
    --blue: #43bad8;
    --darkBlue: #076c7e;
    --grey: #7f7d7c;

    --trim: "Trim Poster Regular";
    --open: "Open Sans", sans-serif;

    --globalPadding: 3vw;
    --headerHeight: 160px;
    @media (max-width: 768px) {
      --headerHeight: 140px;
    }
  }

  @font-face {
    font-family: "Trim Poster Regular";
    src: url("/fonts/TrimPoster-Regular.eot"); /* IE9 Compat Modes */
    src: url("/fonts/TrimPoster-Regular.eot?#iefix") format("embedded-opentype"),
      /* IE6-IE8 */ url("/fonts/TrimPoster-Regular.woff") format("woff"),
      /* Modern Browsers */ url("/fonts/TrimPoster-Regular.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("/fonts/TrimPoster-Regular.svg#e510fe20806674049ce0bc5d9b2fae09")
        format("svg"); /* Legacy iOS */

    font-style: normal;
    font-weight: 400;
  }

  ${normalize()}

  html,
  body {
    color: black;
    font-size: 16px;
    line-height: 1.3;
    font-family: var(--open);
    color: var(--grey);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h5 {
    font-weight: 400;
    font-family: var(--trim);
  }
  * {
    box-sizing: border-box;
  }

  svg {
    fill: currentColor;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    max-width: 100%;
    display: block;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .Cover {
    width: 100%;
    height: 20vw;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    );
    position: absolute;
    left: 0;
    top: 51.4vw;
    z-index: 0;
  }

  .PageIntro {
    padding: 0 var(--globalPadding);
    h2 {
      font-family: var(--trim);
      font-size: 4vw;
      text-align: left;
      color: var(--blue);
      line-height: 1;
      margin: 1em 0;
      @media (max-width: 768px) {
        font-size: 7vw;
      }
    }
  }

  .Badge {
    position: absolute;
    display: block;
    z-index: 5;
    top: calc(var(--headerHeight) + var(--globalPadding));
    width: 320px;
    color: var(--black);
    transform: rotate(27deg);
    right: var(--globalPadding);
    color: var(--black);

    &:after {
      content: "";
      display: block;
      padding-bottom: 27.978%;

      @media (max-width: 768px) {
        width: 50vw;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }
    }
  }
`
export default GlobalStyle
