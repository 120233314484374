import { Link } from "gatsby"
import { darken } from "polished"
import React from "react"
import styled, { css } from "styled-components"

import { GolbalStateContext } from "../context"

const Nav = () => {
  const [globalState] = React.useContext(GolbalStateContext)

  const { menuOpen } = globalState

  return (
    <Wrapper menuOpen={menuOpen}>
      <ul className="list">
        <li className="item">
          <Link activeClassName="active" to="/about/">
            About Us
          </Link>
        </li>
        <li className="item">
          <Link activeClassName="active" to="/osteopathy/">
            Osteopathy
          </Link>
        </li>
        <li className="item">
          <Link activeClassName="active" to="/applied-kinesiology/">
            Applied Kinesiology
          </Link>
        </li>
        <li className="item">
          <Link activeClassName="active" partiallyActive={true} to="/blog/">
            Blog
          </Link>
        </li>
        <li className="item">
          <Link activeClassName="active" partiallyActive={true} to="/services/">
            Services
          </Link>
        </li>
        <li className="item">
          <Link activeClassName="active" to="/contact/">
            Contact
          </Link>
        </li>
      </ul>
    </Wrapper>
  )
}
export default Nav

const Wrapper = styled.nav`
  height: auto;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--blue);
    color: var(--white);
    opacity: 0;
    visibility: hidden;
    ${({ menuOpen }) =>
    menuOpen &&
    css`
        opacity: 1;
        visibility: visible;
      `}
  }

  .list {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  }

  .item {
    list-style-type: none;
    margin: 0 1em;
    letter-spacing: 0.12em;
    @media (max-width: 768px) {
      margin: 0.5em 0;
    }
  }

  a {
    color: var(--blue);
    text-decoration: none;
    font-family: var(--trim);
    position: relative;
    &:hover {
      color: ${darken(0.2, `#43bad8`)};
    }

    @media (max-width: 768px) {
      color: var(--white);
      font-size: 1.5em;
    }
  }

  a.active {
    border-bottom: 2px solid currentColor;
  }
`
